import React from "react";
import GatsbyLink from "gatsby-link";
import { Button, H4, Link, Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Code,
	Header,
	DocsTable,
} from "../../../../../src/docs";

import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Message } from "./message";

export const Meta: DocPageMeta = {
	category: "Feedback",
	title: "Message",
	notepad: "https://hackmd.io/@Ulcvl-fvShmIdi46-hA8JQ/Hyy5N4txY",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Message"
			subTitle="Provide users with essential information, feedback, or status updates related to actions or system states."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Message} />
				<Header.H2>How to select a suitable type of message:</Header.H2>
				<Paragraph>
					Choose a Message type (color and icon) based on its associated semantic meaning.
				</Paragraph>
				<DocsTable>
					<thead>
						<tr>
							<th>Type</th>
							<th>Color</th>
							<th>Icon</th>
							<th>Use Case</th>
							<th>Best Practice</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Positive</td>
							<td>Green</td>
							<td>IconSuccess</td>
							<td>Success messages, confirmations, or positive feedback.</td>
							<td>Clearly state the successful action or outcome.</td>
						</tr>
						<tr>
							<td>Neutral</td>
							<td>Blue</td>
							<td>IconInfoOutline</td>
							<td>General information, status updates, or feedback.</td>
							<td>
								Avoid overusing neutral messages. Reserve for situations where no specific sentiment
								is needed.
							</td>
						</tr>
						<tr>
							<td>Warning</td>
							<td>Yellow</td>
							<td>IconWarningOutline</td>
							<td>
								Potential issues, cautionary alerts, or recommendations for alternative actions.
							</td>
							<td>Provide specific instructions on how to address the warning.</td>
						</tr>
						<tr>
							<td>Negative</td>
							<td>Red</td>
							<td>IconError</td>
							<td>Error messages, negative feedback, or notifications of unsuccessful actions.</td>
							<td>Explain the error clearly and offer guidance for correction.</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The <Code>Message</Code> component features a few properties for adjusting its
					functionality and appearance. The following sections showcase these properties and explain
					when and how to use them.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					This is the default appearance of the <Code>Message</Code> component. Its{" "}
					<Code>type</Code> property is by default set to <Code>neutral</Code>. Make the{" "}
					<Code>Message</Code> disappear when the situation that caused its appearance has been
					resolved.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Use Case</b>: General informational messages or feedback where no specific urgency
							or sentiment is required.
						</>,
						<>
							<b>Best Practice</b>: Keep the message concise and focused. Use clear and actionable
							language.
						</>,
					]}
				/>
				<Paragraph>
					<b>Composition</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Icon</b>: Visually represents the message type (positive, neutral, warning,
							negative.).
						</>,
						<>
							<b>Title</b> (Optional): Briefly summarizes the message content (use Message.Title
							component).
						</>,
						<>
							<b>Message Text</b>: The main content of the message.
						</>,
						<>
							<b>Footer</b> (Optional): Contains links or buttons for user actions (use
							Message.Footer component).
						</>,
					]}
				/>
				<Example fn={defaultExample} />
				<Header.H3>Types</Header.H3>
				<Paragraph>
					Use the <Code>type</Code> property to change the color of and icon of a{" "}
					<Code>Message</Code>. Every color and icon combination has a specific semantic meaning or
					purpose. Consult the table above to find out which <Code>type</Code> suits your use case.
				</Paragraph>
				<Example fn={typesExample} />
				<Header.H3>Title</Header.H3>
				<Paragraph>
					Use the namespaced <Code>Message.Title</Code> component to provide a <Code>Message</Code>{" "}
					with a <GatsbyLink to="/lib/components/Text/Heading">Heading</GatsbyLink>. Note, it's
					required to specify the heading level of the title. This does not affect the appearance of
					the title. It will always look like an <Code>H6</Code>.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Use Case</b>: Longer messages where a title helps to summarize the main point.
						</>,
						<>
							<b>Best Practice</b>: Keep the title concise and descriptive. Use a heading level (H6)
							that reflects the content's hierarchy.
						</>,
					]}
				/>
				<Example fn={titleExample} />
				<Header.H3>Footer</Header.H3>
				<Paragraph>
					Use the namespaced <Code>Message.Footer</Code> component when the <Code>Message</Code>{" "}
					encourages users to navigate somewhere or perform an action. It can, for example, contain
					a <GatsbyLink to="/lib/components/Navigation/Link">Link</GatsbyLink>, as shown below.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Use Case</b>: Messages that require user interaction, such as confirmations,
							prompts, or calls to action.
						</>,
						<>
							<b>Best Practice</b>: Use clear and concise button labels. Prioritize primary actions.
							Limit the number of buttons for clarity.
						</>,
					]}
				/>
				<Example fn={footerLinkExample} />
				<Paragraph>
					The <Code>Message.Footer</Code> can also contain{" "}
					<GatsbyLink to="/lib/components/Actions and controls/Button">Buttons</GatsbyLink>. Try to
					limit this to a single <Code>primary</Code> <Code>Button</Code> or a <Code>primary</Code>{" "}
					and <Code>default</Code> <Code>Button</Code>, as exemplified below.
				</Paragraph>
				<Example fn={footerButtonsExample} />
				<Header.H3>Compact</Header.H3>
				<Paragraph>
					Use the <Code>compact</Code> property to reduce the size and internal spacing of the{" "}
					<Code>Message</Code>. Use this property when the <Code>Message</Code> is placed in a small
					container, such as a <GatsbyLink to="/lab/components/Overlay/Popover">Popover</GatsbyLink>
					.
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Use Case</b>: When the Message is placed in a small container, such as a Popover.
						</>,
						<>
							<b>Best Practice</b>: Ensure the content remains legible and accessible even in the
							compact form.
						</>,
					]}
				/>
				<Example fn={compactExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Message}
					initialProps={{
						children: <Paragraph>This is a message.</Paragraph>,
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Message</Code> when
					</Paragraph>
					<Ul
						items={[
							<>
								Provide users with important information or feedback about a task, action, or state.
							</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Message</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>
							</>,
							<>
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>
							</>,
							<>
								<GatsbyLink to="/lab/components/Overlay/Popover">Popover</GatsbyLink>
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								Siteimprove Design System: Adhere to Siteimprove's guidelines for color, typography,
								and spacing. If you are not using a component from Fancy, match the styling of your{" "}
								<Code>Message</Code> to existing components for visual consistency.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								The <Code>Message</Code> needs to be dismissible. Instead, use a{" "}
								<GatsbyLink to="/lab/components/Feedback/Toast">Toast</GatsbyLink> or{" "}
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>.
							</>,
							<>
								You need to show a validation error or warning in a form. These kinds of messages
								are built into the{" "}
								<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
									Form Element Wrapper
								</GatsbyLink>{" "}
								component.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul
						items={[
							<>
								Make sure to pick an appropriate{" "}
								<GatsbyLink to="/lib/components/Text/Heading#accessibility">
									heading level
								</GatsbyLink>{" "}
								for the <a href="#title">title</a> of the <Code>Message</Code>.
							</>,
						]}
					/>
					<H4>For developers</H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>Use clear, concise, and actionable language</Paragraph>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => (
	<Message>
		<Paragraph>This is a default message.</Paragraph>
	</Message>
);

const typesExample = () => (
	<div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
		<Message type="positive">
			<Paragraph>Use "positive" messages when something has gone well or is going well.</Paragraph>
		</Message>
		<Message type="neutral">
			<Paragraph>Use "neutral" messages when something is neither positive or negative.</Paragraph>
		</Message>
		<Message type="warning">
			<Paragraph>Use "warning" messages when something requires attention.</Paragraph>
		</Message>
		<Message type="negative">
			<Paragraph>Use "negative" messages when something has gone wrong.</Paragraph>
		</Message>
	</div>
);

const titleExample = () => (
	<Message type="neutral">
		<Message.Title headingLevel="h3">Lorem ipsum</Message.Title>
		<Paragraph>
			Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the
			industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
			type and scrambled it to make a type specimen book.
		</Paragraph>
	</Message>
);

const footerLinkExample = () => (
	<Message type="neutral">
		<Message.Title headingLevel="h3">Lorem ipsum</Message.Title>
		<Paragraph>
			Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the
			industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
			type and scrambled it to make a type specimen book.
		</Paragraph>
		<Message.Footer>
			<Link href="#">Lorem ipsum</Link>
		</Message.Footer>
	</Message>
);

const footerButtonsExample = () => (
	<Message type="neutral">
		<Message.Title headingLevel="h3">Lorem ipsum</Message.Title>
		<Paragraph>
			Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the
			industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
			type and scrambled it to make a type specimen book.
		</Paragraph>
		<Message.Footer>
			<Button variant="primary">Primary action</Button>
			<Button>Alternative action</Button>
		</Message.Footer>
	</Message>
);

const compactExample = () => (
	<Message type="neutral" compact>
		<Message.Title headingLevel="h3">Lorem ipsum</Message.Title>
		<Paragraph>
			Lorem Ipsum is simply dummy text of the printing and typesetting industry. It has been the
			industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
			type and scrambled it to make a type specimen book.
		</Paragraph>
		<Message.Footer>
			<Button variant="primary">Primary action</Button>
			<Button>Alternative action</Button>
		</Message.Footer>
	</Message>
);
